import React from 'react'

const HomeButtons = () => {
  return (
    <div className='mt-12 container mx-auto flex flex-col lg:flex-row stretch justify-between'>
      <div data-aos="fade-right" className='lg:w-[47%] flex flex-col justify-center'>
        <a
          href="https://appuntamentoinlinea.it/mirafiori/#/login"
          className='flex flex-col justify-center items-center flex-grow  rounded-lg bg-blue-primary py-4 px-5 w-full text-center'
        >
          <h2 className='text-white font-light text-4xl mb-2'>
            <span className='font-bold'>PRENOTAZIONI</span> ONLINE
          </h2>

          <p className='leading-[1.5] w-[80%] mx-auto text-white/60 font-light text-lg mb-4'>
            Tramite la prenotazione online si potrà usufruire
            del 5% di sconto sulla prestazione
          </p>
        </a>
        <img className='w-full' src="/images/shadow.png" />

      </div>

      <div data-aos="fade-left" className='lg:w-[47%] flex flex-col justify-center'>
        <a
          href="https://refertoinlineait.serversicuro.it/login.php?lab=23"
          className='flex flex-col justify-center items-center flex-grow  rounded-lg bg-blue-primary py-4 px-5 w-full text-center'
        >
          <h2 className='text-white font-light text-4xl mb-2'>
            <span className='font-bold'>REFERTI</span> ONLINE
          </h2>

          <p className='leading-[1.5] w-[80%] mx-auto text-white/60 font-light text-lg mb-4'>
            Clicca per accedere al portale e consultare i referti
          </p>

        </a>
        <img className='w-full' src="/images/shadow.png" />

      </div>
    </div>
  )
}

export default HomeButtons